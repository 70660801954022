import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "../components/layout.css"
/*import MobileNav from "./mobilenav"*/

const Header = () => (
  <header
    className={"navbar"}
    style={{
      background: `--dcm-darkblue`,
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
        textAlign: "center",
        color: "white",
      }}
    >
      <div id="nav" className={"navtext"} style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            padding: `0 1rem`,
          }}
        >
          Home
        </Link>
        |
        <Link
          to="#about-wrapper"
          style={{
            padding: `0 1rem`,
          }}
        >
          About Us
        </Link>
        |
        <Link
          to="#suppliers"
          style={{
            padding: `0 1rem`,
          }}
        >
          Our Suppliers
        </Link>
        |
        <Link
          to="#contact"
          style={{
            padding: `0 1rem`,
          }}
        >
          Contact Us
        </Link>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  menu: PropTypes.string,
}

Header.defaultProps = {
  menu: ``,
}

export default Header

//MOBILE NAV WIP
//<div id={"burger-nav"}>
//<MobileNav />
//</div>
